@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: "Arimo", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
    monospace;
}

.str-chat__edit-message-form {
  .str-chat__edit-message-form-options {
    button {
      margin: 0 8px !important;
    }
  }
}

.str-chat__message-text-inner {
  ol,
  ul {
    padding-left: 20px;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  code,
  kbd,
  samp,
  pre {
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
      monospace;
    @apply bg-gray-50 rounded-lg px-2 py-1 italic;
  }
}

// .channel-preview-end {
//   &-unread {
//     @apply bg-red-500;

//     border-radius: 999px;
//     padding: 0px 4px 2px 4px;
//     display: inline-block;

//     position: relative;
//     left: 110px;
//     top: 15px;

//     &.unreadCount {
//       visibility: hidden;
//     }

//     &-text {
//       @apply text-white;

//       font-size: 12px;
//       font-weight: 700;
//       line-height: 7px;
//       text-align: center;
//     }
//   }
// }
